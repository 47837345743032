import api from '../../utils/api'

// 授权
export async function history_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         categories: [
  //           {
  //             standard_diagnosis_id: 1,
  //             standard_diagnosis_name: '心脏病',
  //           },
  //           {
  //             standard_diagnosis_id: 2,
  //             standard_diagnosis_name: '高血压',
  //           },
  //           {
  //             standard_diagnosis_id: 3,
  //             standard_diagnosis_name: '胃炎',
  //           },
  //           {
  //             standard_diagnosis_id: 4,
  //             standard_diagnosis_name: '心脏病',
  //           },
  //           {
  //             standard_diagnosis_id: 5,
  //             standard_diagnosis_name: '高血压',
  //           },
  //           {
  //             standard_diagnosis_id: 6,
  //             standard_diagnosis_name: '胃炎',
  //           },
  //         ],
  //         records: {
  //           page_number: 0,
  //           page_size: 5,
  //           number_of_elements: 5,
  //           total_elements: 5,
  //           total_pages: 4,
  //         },
  //         data: [
  //           {
  //             group_month: 1,
  //             card_type: 1,
  //             card_title: '文章标题',
  //             card_desc: '文章描述',
  //             standard_diagnosis_id: 1,
  //             standard_diagnosis_name: '疾病名称1',
  //             send_time: new Date(),
  //             read: false,
  //             url: '/article?id=5'
  //           },
  //           {
  //             group_month: 2,
  //             card_type: 2,
  //             card_title: '文章标题2',
  //             card_desc: '文章描述2',
  //             standard_diagnosis_id: 2,
  //             standard_diagnosis_name: '疾病名称2',
  //             send_time: new Date(),
  //             read: true,
  //             url: '/article?id=5'
  //           },
  //           {
  //             group_month: 1,
  //             card_type: 3,
  //             card_title: '文章标题3',
  //             card_desc: '文章描述3',
  //             standard_diagnosis_id: 3,
  //             standard_diagnosis_name: '疾病名称3',
  //             send_time: new Date(),
  //             read: true,
  //             url: '/article?id=5'
  //           },
  //           {
  //             group_month: 2,
  //             card_type: 2,
  //             card_title: '文章标题4',
  //             card_desc: '文章描述4',
  //             standard_diagnosis_id: 3,
  //             standard_diagnosis_name: '疾病名称4',
  //             send_time: new Date(),
  //             read: true,
  //             url: '/article?id=5'
  //           },
  //           {
  //             group_month: 4,
  //             card_type: 1,
  //             card_title: '文章标5',
  //             card_desc: '文章描述5',
  //             standard_diagnosis_id: 2,
  //             standard_diagnosis_name: '疾病名称5',
  //             send_time: new Date(),
  //             read: false,
  //             url: '/article?id=5'
  //           },
  //         ],
  //       }
  //     })
  //   }, 500)
  // })

  return api.get(`/api/v1/mp/im/logs`, {params:payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}







