<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <div class="header_out">
        <div class="search_box">
          <van-search
            v-model="form.name_like"
            shape="round"
            placeholder="请输入搜索关键词"
          />
        </div>
        <div class="tab_box van-hairline--top">
          <div
            class="left_box"
            :class="active_index === -1 ? 'active' : ''"
            @click="press_caregory(-1)"
          >
            <span> 全部 </span>
          </div>
          <div class="right_box" v-if="categories && categories.length">
            <div
              class="tab_list"
              v-for="(item, index) in categories"
              :key="index"
              @click="press_caregory(index, item.standard_diagnosis_id)"
              :class="active_index === index ? 'active' : ''"
            >
              <span>{{ item.standard_diagnosis_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card_out">
        <van-list
          v-model="loading"
          loading-text=" "
          :finished="finished"
          @load="onLoad"
        >
          <div
            class="card_item"
            v-for="(item, index) in month_items"
            :key="index"
            v-show="item.list.length"
          >
            <div
              class="month_title"
              :class="item.open ? 'open' : ''"
              @click.stop="month_click(item)"
            >
              <span v-html="year_month(item.list[0].group_month)"></span>
              <div class="month_trangle"></div>
            </div>
            <div v-if="item.open">
              <div
                class="card"
                v-for="(it, idx) in item.list"
                :key="idx"
                @click="card_click(it)"
              >
                <div class="img_box" :class="it.read ? '' : 'dot'">
                  <img
                    src="../../assets/img/history-1.png"
                    v-if="it.card_type === 1"
                    width="44"
                    alt=""
                  />
                  <img
                    src="../../assets/img/history-2.png"
                    v-if="it.card_type === 2"
                    width="44"
                    alt=""
                  />
                  <img
                    src="../../assets/img/history-3.png"
                    v-if="it.card_type === 3"
                    width="44"
                    alt=""
                  />
                </div>
                <div class="content_box">
                  <div class="top_box">
                    <div class="top_left">
                      <div class="card_title">{{ it.card_title }}</div>
                      <div class="card_desc">{{ it.card_desc || "描述" }}</div>
                    </div>
                    <div class="top_right" v-if="!it.read">未阅读</div>
                  </div>
                  <div class="bottom_box">
                    <div class="bottom_left">
                      {{ it.standard_diagnosis_name }}
                    </div>
                    <div class="bottom_right">
                      {{ format_date(it.send_time, "yyyy-MM-dd HH:mm") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="no-data" v-if="!loading && !list.length">
            <img width="150" src="../../assets/img/no-data.png" />
            暂无数据
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { history_info } from "./service";
import { format_date } from "../../utils/format";
export default {
  data() {
    return {
      loading: false,
      form: {
        name_like: "",
        standard_diagnosis_id: "",
        page: -1,
        page_size: 5,
      },
      list: [],
      finished: false,
      data: null,
      categories: [],
      active_index: -1,
      month_items: null,
      timer: null,
      delay: 500,
      count: 0,
    };
  },
  computed: {},
  components: {},
  watch: {
    "form.name_like"(new_value, old_value) {
      if (new_value !== old_value) {
        this.list = [];
        clearTimeout(this.timer);
        this.timer = setTimeout(this.reset_from, this.delay);
      }
    },
  },
  created() {
  },
  destroyed() {},
  methods: {
    format_date,
    onLoad() {
      this.form.page++;
      this.get_history();
    },
    async get_history() {
      this.loading = true;
      try {
        const { data } = await history_info(this.form);
        if (!this.count) {
          this.categories = data.categories;
        }
        this.count++;
        this.list = this.list.concat(data.records.data);
        if (
          !this.list.length ||
          this.list.length >= data.records.total_elements
        ) {
          this.finished = true;
        }
        this.set_data();
      } finally {
        this.loading = false;
      }
    },
    press_caregory(index, id) {
      this.active_index = index;
      this.form.standard_diagnosis_id = id ? id : "";
      this.reset_from();
    },
    reset_from() {
      this.form.page = -1;
      this.list = [];
      this.finished = false;
      this.onLoad();
    },
    build_two(list, fn) {
      const groups = {};
      list.forEach(function (o) {
        const group = JSON.stringify(fn(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return groups;
    },
    set_data() {
      let normal_obj = this.build_two(this.list, (li) => {
        return li.group_month;
      });
      let now_obj = {};
      Object.keys(normal_obj)
        .sort()
        .forEach(function (key) {
          now_obj[key] = normal_obj[key];
        });
      let arr = [];
      for (let i in now_obj) {
        let obj = {
          open: true,
          list: now_obj[i]
        }
        arr.push(obj);
      }
      this.month_items = [...arr.reverse()]
    },
    month_click(item) {
      item.open = !item.open;
    },
    card_click(item) {
      this.$router.push({
        path: item.url,
      });
    },
    year_month(num) {
      let str = num.toString();
      let year = parseInt(str.substring(0, 4));
      let month = parseInt(str.substring(4, 6));
      const now_year = new Date().getFullYear();
      return year === now_year
        ? month + '<span style="font-size: 14px;">月</span>'
        : year +
            '<span style="font-size: 14px;">年</span>' +
            month +
            '<span style="font-size: 14px;">月</span>';
    },
  },
};
</script>
<style scoped>
.g_main_content {
  background: #f2f7f8;
  padding-bottom: 20px;
}

.header_out {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tab_box {
  display: flex;
  align-items: center;
  height: 44px;
  background: #fff;
}

.left_box,
.tab_list {
  width: 75px;
  font-size: 14px;
  color: #9c9c9c;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    line-height: 40px;
    text-align: center;
    display: inline-block;
    padding: 0 5px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 40px;
  }

  &.active {
    & span {
      border-bottom: solid 3px #1890ff;
    }
  }
}

.right_box {
  flex: 1;
  display: -webkit-box;
  align-items: center;
  overflow-x: auto;
}

.card_item {
  margin-top: 28px;
  padding: 0 16px;
}

.month_title {
  position: relative;
  font-size: 22px;
  color: #242938;
  display: inline-block;
}

.month_style {
  font-size: 14px;
}

.month_trangle {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 7px solid #565656;
  border-bottom: 4px solid transparent;
  position: absolute;
  right: -10px;
  bottom: 2px;
  transition: all 0.3s;
}

.month_title.open {
  & .month_trangle {
    transform: rotate(90deg);
  }
}

.card {
  margin-top: 12px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  opacity: 1;
  border-radius: 8px;
  padding: 16px 10px;
  display: flex;
}

.img_box {
  position: relative;
  border-radius: 50%;

  &.dot::before {
    content: "";
    width: 6px;
    height: 6px;
    background: red;
    position: absolute;
    border-radius: 50%;
    top: 5px;
    right: 5px;
  }
}

.content_box {
  padding-left: 8px;
  flex: 1;
}

.top_box,
.bottom_box {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 17px;
  color: #808080;
}

.card_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #3d3d3d;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 20px;
}

.card_desc {
  margin-top: 2px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 34px;
}

.bottom_box {
  padding-top: 12px;
}

.no-data {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #565656;
  & img {
    margin-bottom: 10px;
  }
}
.top_left {
  flex: 1;
}

.top_right {
  width: 40px;
  margin-left: 10px;
}

.bottom_left {
  flex: 1;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 20px;
}
</style>
